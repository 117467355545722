<template>
    <div>
        <div class="add-btn" @click="$router.push('/hoss/clientRecord')">
            <van-icon name="plus" size="18px" />
        </div>
        <van-list v-model="loading" :finished="finished" @load="getList">
            <div
                v-for="(item, index) in list"
                :key="item.id"
                class="card"
                @click="$router.push(`/hoss/clientRecord?clientId=${item.id}&isNeedCreateClient=true`)"
            >
                <img
                    fit="cover"
                    class="card-img"
                    :src="item.portrait || require('@/assets/images/defaultPhoto.png')"
                />
                <div class="card-content">
                    <span class="name">
                        <span>{{ item.nickName }}</span>
                        <span v-if="item.name">（{{ item.name }}）</span>
                    </span>
                    <div class="card_bottom">
                        <van-icon name="delete-o" size="18px" @click.stop="del(item, index)" />
                    </div>
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
import { incompleteCreation, delClient } from "@/api/hoss/index";
import { Dialog } from "vant";
export default {
    components: {},
    props: {},
    data() {
        return {
            loading: false,
            finished: false,

            pageNum: 0,
            pageSize: 10,
            list: [],
        };
    },
    created() {},
    methods: {
        async getList() {
            this.pageNum++;
            const { pageNum, pageSize } = this;
            const { value } = await incompleteCreation({ pageNum, pageSize });
            this.list.push(...value.list);
            this.finished = value.lastPage;
            this.loading = false
        },
        del({ id }, index) {
            Dialog.confirm({
                title: "删除确认",
                message: "删除后不可恢复，请确认",
                className: "hoss-dialog",
            })
                .then(async () => {
                    await delClient(id);
                    this.list.splice(index, 1);
                })
                .catch(() => {
                    // on cancel
                });
        },
    },
};
</script>

<style scoped lang="less">
.add-btn {
    height: 60px;
    margin: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.van-list,
.add-btn {
    background-color: var(--cardBackgroundColor);
    margin: 20px 10px;
    border-radius: 10px;
    overflow: hidden;
}
.card {
    padding: 8px;
    border-bottom: 1px solid var(--separatorColor);
    display: flex;
    align-items: center;
    &:last-child {
        border: none;
    }

    .card-img {
        width: 100px;
        height: 100px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 8px;
        flex-shrink: 0;
    }

    .card-content {
        flex: 1;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
            font-size: 16px;
        }
        .card_bottom {
            text-align: right;
        }
    }
}
</style>
